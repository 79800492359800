import React from 'react';

import { useHotjar } from 'app/shared/utils/useHotjar';

function withHotjar(
  PassedComponent: React.ComponentType<React.PropsWithChildren<any>>
) {
  const WrappedComponent = (props: any) => {
    useHotjar();
    return <PassedComponent {...props} />;
  };

  return WrappedComponent;
}

export default withHotjar;
