import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { CommonTheme, FontFamily } from 'app/shared/theme';
import {
  withFlexCSS,
  WithFlexProps,
  withFontCSS,
  WithFontProps,
} from 'app/shared/components/styleUtils';
import { Icon } from 'app/shared/components/atoms';
import {
  ActionButton,
  ActionButtonProps,
} from 'app/shared/components/molecules';
import { ReactComponent as AppleLogo } from 'icons/apple.svg';
import { ReactComponent as FacebookLogo } from 'icons/facebook.svg';
import { ReactComponent as GoogleLogo } from 'icons/google.svg';

export enum ThirdPartyAuthMode {
  SIGN_UP,
  SIGN_IN,
}

export type ThirdPartyAuthProvider = 'Apple' | 'Google' | 'Facebook';

interface CopyProps {
  provider: ThirdPartyAuthProvider;
}

export const ThirdPartyAuthButtonCopy = ({ provider }: CopyProps) => {
  return (
    <FormattedMessage id="authentication.continueWith" values={{ provider }} />
  );
};

interface ButtonCSSProps extends WithFontProps, WithFlexProps {
  backgroundColor: string;
  color: string;
  borderColor: string;
  theme: CommonTheme;
}

const StyledActionButton = styled(ActionButton)<ButtonCSSProps>`
  ${({ backgroundColor, color, borderColor }) => css`
    color: ${color};
    background-color: ${backgroundColor};
    border: 1px solid ${borderColor} !important;

    &:hover,
    &:focus,
    &:active {
      color: ${color};
      background-color: ${backgroundColor};
      border: 1px solid ${borderColor} !important;
    }
  `}

  ${withFontCSS};
  ${withFlexCSS};
`;

interface ThirdPartyAuthButtonProps extends ActionButtonProps {
  provider: ThirdPartyAuthProvider;
  backgroundColor: string;
  color: string;
  borderColor: string;
}

export const ThirdPartyAuthButton = ({
  provider,
  ...props
}: ThirdPartyAuthButtonProps) => {
  const logos = {
    Apple: AppleLogo,
    Google: GoogleLogo,
    Facebook: FacebookLogo,
  };

  return (
    <StyledActionButton
      fullWidth
      {...props}
      variant="tertiary"
      fontFamily={provider.toLowerCase() as FontFamily}
      fontWeight="medium"
      textTransform="none"
      controlChildSVGStyle={false}
    >
      <Icon icon={logos[provider]} size="md" useNativeColors={true} />
      <ThirdPartyAuthButtonCopy provider={provider} />
    </StyledActionButton>
  );
};
