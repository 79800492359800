import { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';

export interface WithInputStyleProps {
  isSuccessful?: boolean;
  hasError?: boolean;
  backgroundColor?: string;
  placeholderColor?: string;
  name?: string;
  color?: string;
  invertColors?: boolean;
  isLocked?: boolean;
  cursor?: string;
}

interface WithInputStyleCSSProps extends WithInputStyleProps {
  theme: CommonTheme;
}

export const withInputStyleCSS = ({
  theme,
  isSuccessful = false,
  hasError,
  backgroundColor,
  placeholderColor,
  color,
  invertColors,
  isLocked = false,
  cursor,
}: WithInputStyleCSSProps) => css`
  width: 100%;
  height: ${theme.dimensions.input.height};
  font-family: ${theme.fonts.regular};

  border-radius: ${theme.borderRadius.input};
  border: 1px solid
    ${
      invertColors
        ? theme.colors.inputBorder.inverted
        : theme.colors.inputBorder.default
    };
  padding: 0 ${theme.ruler[3]}px;
  outline: none;
  box-shadow: none;

  ${
    placeholderColor &&
    `
    ::placeholder {
      color: ${placeholderColor};
    }
  `
  }

  ${isLocked && 'pointer-events: none;'}
  ${cursor && `cursor: ${cursor};`}

  background-color: ${
    backgroundColor
      ? backgroundColor
      : invertColors
        ? theme.colors.primary.black.light
        : theme.colors.primary.white.lightest
  };
  color: ${
    color
      ? color
      : invertColors
        ? theme.colors.primary.white.base
        : theme.colors.primary.black.base
  };
  line-height: 100%;

  &:hover {
    border-color: ${
      invertColors
        ? theme.colors.inputBorder.invertedHover
        : theme.colors.inputBorder.hover
    };
  }

  &:focus, &:focus-within, &:focus-visible {
    border-color: ${
      invertColors
        ? theme.colors.inputBorder.invertedFocus
        : theme.colors.inputBorder.focus
    };
  }

  &:disabled {
    pointer-events: none;
    color: ${theme.colors.button.primary.disabled};
    ${
      !isSuccessful &&
      !hasError &&
      `border-color: ${theme.colors.button.primary.disabled};`
    }
  }

  ${theme.media.mobile`
    font-size: ${theme.fontSizes.fontSize100.mobile};
  `};

  ${theme.media.smallDesktop`
    font-size: ${theme.fontSizes.fontSize100.desktop};
  `};

  ${
    isSuccessful &&
    css`
      color: ${theme.colors.messaging.success[invertColors ? 'light' : 'dark']};
      border: 1px solid
        ${theme.colors.messaging.success[invertColors ? 'light' : 'dark']};

      &:hover {
        border-color: ${
          theme.colors.messaging.success[invertColors ? 'light' : 'dark']
        };
      }
    `
  }

  ${
    hasError &&
    css`
      color: ${theme.colors.messaging.error[invertColors ? 'light' : 'dark']};
      border: 1px solid
        ${theme.colors.messaging.error[invertColors ? 'light' : 'dark']};

      &:hover {
        border-color: ${
          theme.colors.messaging.error[invertColors ? 'light' : 'dark']
        };
      }
    `
  }

  &::-webkit-search-cancel-button {
    display: none;
  }
`;
