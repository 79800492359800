export const removeEntriesByKey = (
  object: { [key: string]: any },
  keysToRemove: string[]
) =>
  Object.keys(object)
    .filter((key) => !keysToRemove.includes(key))
    .reduce((obj: { [key: string]: any }, key: string) => {
      obj[key] = object[key];
      return obj;
    }, {});

export const keepOnlyEntriesByKey = (
  object: { [key: string]: any },
  keysToKeep: string[]
) =>
  Object.keys(object)
    .filter((key) => keysToKeep.includes(key))
    .reduce((obj: { [key: string]: any }, key: string) => {
      obj[key] = object[key];
      return obj;
    }, {});

export const sortEntriesByKey = (object: { [key: string]: any }) =>
  Object.keys(object)
    .sort()
    .reduce((obj: { [key: string]: any }, key: string) => {
      obj[key] = object[key];
      return obj;
    }, {});

export const objectListFilteredByBooleanConditionOnKeys = (
  objects: any[],
  keysAndBooleanConditions: { [key: string]: any }
) =>
  objects.filter(
    (obj) =>
      // @ts-ignore
      !(obj.key in keysAndBooleanConditions) ||
      // @ts-ignore
      keysAndBooleanConditions[obj.key]
  );

export const objectFilteredByBooleanConditionOnKeys = (
  obj: { [key: string]: any },
  keysAndBooleanConditions: { [key: string]: any }
): typeof obj => {
  let newObj: typeof obj = {};

  for (const key in obj) {
    if (!(key in keysAndBooleanConditions) || keysAndBooleanConditions[key]) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};

export const fromEntries = (pairs: any[]) => {
  const assign = (object: any, [key, value]: any) =>
    Object.assign(object, { [key]: value });
  return pairs.reduce(assign, {});
};

export const objectForObjectId = (objList: any, objId: number | null) =>
  objList.find((obj: any) => obj && obj.id === objId);

export const objectForObjectKey = (objList: any, objKey: string | null) =>
  objList.find((obj: any) => obj && obj.key === objKey);

export const isEmpty = (obj: any) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;
