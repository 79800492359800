import React from 'react';

import Intl from 'app/shared/context/Intl';

import { RenderRoutes } from './RenderRoutes';

import 'src/assets/fonts.css';

const App = () => {
  return (
    <Intl>
      <RenderRoutes />
    </Intl>
  );
};

export default App;
