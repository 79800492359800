import React, { RefObject } from 'react';
import { useFormikContext } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';

import { isReviewApp } from 'app/lib/environmentChecker';
import { useAnalyticsContext } from 'app/shared/utils';
import { Box } from 'app/shared/components/atoms';

export const InvisibleCaptcha: React.FC<
  React.PropsWithChildren<{
    reCaptchaRef: RefObject<ReCAPTCHA>;
    setDisplayCaptchaError: React.Dispatch<React.SetStateAction<boolean>>;
  }>
> = ({ reCaptchaRef, setDisplayCaptchaError }) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { setSubmitting } = useFormikContext();

  if (isReviewApp() || process.env.APP_ENV === undefined) {
    return null;
  }

  function onChange(token: string | null) {
    if (!token) {
      trackAnalyticsEvent('ReCAPTCHA', {
        token: null,
        error: 'ReCAPTCHA onChange called with null token',
      });
      throw new Error('ReCAPTCHA onChange called with null token');
    }

    trackAnalyticsEvent('ReCAPTCHA', {
      token,
    });
    setDisplayCaptchaError(false);
  }

  function onError() {
    trackAnalyticsEvent('ReCAPTCHA', {
      token: null,
      error: 'ReCAPTCHA threw an unspecified error',
    });
    setDisplayCaptchaError(true);
    setSubmitting(false);
  }

  return (
    <Box display="flex" h="0px">
      <ReCAPTCHA
        sitekey={
          process.env.RECAPTCHA_SITE_KEY ||
          '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        }
        size="invisible"
        ref={reCaptchaRef}
        badge="inline"
        onChange={onChange}
        onErrored={onError}
      />
    </Box>
  );
};
