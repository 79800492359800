import React from 'react';
import { useIntl } from 'react-intl';

import { FontSizePropKey } from 'app/shared/theme';
import { Typography } from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';

export const ProtectedByReCAPTCHA: React.FC<
  React.PropsWithChildren<{
    fontSize?: FontSizePropKey;
  }>
> = ({ fontSize = 'fontSize75' }) => {
  const { formatMessage } = useIntl();

  return (
    <Typography fontSize={fontSize}>
      {formatMessage({ id: 'recaptcha.badge.intro' })}
      <Link
        data-qaid="google-privacy-policy"
        href="https://policies.google.com/privacy"
        fontWeight="regular"
        fontSize={fontSize}
        openInNewTab={true}
        textDecoration="underline !important"
      >
        {formatMessage({
          id: 'shared.privacyPolicy',
        })}
      </Link>
      {formatMessage({ id: 'shared.termsAndConditions.midfix' })}
      <Link
        data-qaid="google-terms-of-service"
        href="https://policies.google.com/terms"
        fontWeight="regular"
        fontSize={fontSize}
        openInNewTab={true}
        textDecoration="underline !important"
      >
        {formatMessage({ id: 'shared.termsOfService' })}
      </Link>
      {formatMessage({ id: 'recaptcha.badge.endfix' })}
    </Typography>
  );
};
