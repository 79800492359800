import styled, { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import {
  withSpacingCSS,
  WithSpacingProps,
  withZIndexCSS,
  WithZIndexProps,
} from 'app/shared/components/styleUtils';

export interface PortalTransitionProps {
  isShowing: boolean;
  transitionSpeed: number;
}

export const PortalUnderlay = styled.div<PortalTransitionProps>`
  ${({ theme, isShowing, transitionSpeed }) => css`
    background: ${theme.colors.primary.black.base};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    opacity: ${isShowing ? '55%' : '0'};
    visibility: ${isShowing ? 'visible' : 'hidden'};
    transition: opacity ${transitionSpeed}ms ease,
      visibility ${transitionSpeed}ms ease;
    pointer-events: ${isShowing ? 'auto' : 'none'};
    z-index: ${isShowing ? theme.zIndex.portalUnderlay : '0'};
  `}
`;

interface PortalContainerCSSProps extends WithZIndexProps, WithSpacingProps {
  theme: CommonTheme;
}

export const PortalContainer = styled.div<PortalContainerCSSProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;

  ${withZIndexCSS};
  ${withSpacingCSS};
`;
