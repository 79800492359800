import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { WithPositionProps } from 'app/shared/components/styleUtils';
import { Icon } from 'app/shared/components/atoms';
import { Box } from 'app/shared/components/atoms';
import { ReactComponent as CheckCircle } from 'icons/circle-check.svg';
import { ReactComponent as ResetIcon } from 'icons/delete.svg';
import { ReactComponent as AlertCircle } from 'icons/info.svg';

interface Props extends WithPositionProps {
  iconType?: 'ERROR' | 'SUCCESS' | 'RESET' | null;
  onClickResetInput?: () => void;
  invertColors?: boolean;
  'data-qaid'?: string;
}

export const InputStatusIcon: React.FC<React.PropsWithChildren<Props>> = ({
  iconType,
  position,
  right,
  onClickResetInput = () => {},
  invertColors,
  'data-qaid': qaId = 'input-status-icon',
}) => {
  const theme = useCurrentTheme();

  if (!iconType) {
    return null;
  }

  const iconsConfig: Record<string, any> = {
    ERROR: {
      icon: AlertCircle,
      strokeColor:
        theme.colors.messaging.error[invertColors ? 'light' : 'dark'],
      fillColor: 'none',
      onClickAction: () => {},
    },
    SUCCESS: {
      strokeColor:
        theme.colors.messaging.success[invertColors ? 'light' : 'dark'],
      icon: CheckCircle,
      fillColor: 'none',
      onClickAction: () => {},
    },
    RESET: {
      strokeColor: invertColors
        ? theme.colors.primary.white.base
        : theme.colors.primary.black.base,
      fillColor: invertColors
        ? theme.colors.primary.black.base
        : theme.colors.primary.white.lightest,
      icon: ResetIcon,
      onClickAction: onClickResetInput,
      cursor: 'pointer',
    },
  };

  const iconConfig = iconsConfig[iconType];

  return (
    <Box position={position} right={right} onClick={iconConfig.onClickAction}>
      <Icon
        strokeColor={iconConfig.strokeColor}
        size="md"
        fillColor={iconConfig.fillColor}
        icon={iconConfig.icon}
        cursor={iconConfig.cursor}
        data-qaid={`${qaId}-${iconType}`}
      />
    </Box>
  );
};
