import packageJson from '../../../package.json';

interface Config {
  version: string;
  env: string;
  isDev: boolean;
  isProd: boolean;
  port: number;
  graphqlEndpoint: string;
  devServer: string;
  googleTagManager: {
    enabled: boolean;
    key: string;
  };
}

const isClient = typeof window !== 'undefined';
const runtimeEnv = isClient ? window.env || process.env : process.env;
const env = runtimeEnv.NODE_ENV || 'development';

const config: Config = {
  version: packageJson.version,
  env,
  isDev: env === 'development',
  isProd: env === 'production',
  port: Number(runtimeEnv.PORT || 3000),
  graphqlEndpoint: String(process.env.API_URL),
  devServer: String(runtimeEnv.DEV_SERVER),
  googleTagManager: {
    enabled: true,
    key: 'GTM-P7H439',
  },
};

export default config;
