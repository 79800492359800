import React from 'react';
import styled, { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import {
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';

export interface DividerProps
  extends React.ComponentPropsWithoutRef<'div'>,
    WithSpacingProps {
  color?: string;
}

interface DividerCSSProps extends DividerProps {
  theme: CommonTheme;
}

export const Divider = styled.div<DividerCSSProps>`
  ${({ theme, color }) => css`
    border-bottom: 0;
    border-top: 1px solid ${color || theme.colors.primary.black.base};
    width: 100%;
  `}

  ${withSpacingCSS};
`;
