import { css } from 'styled-components';

import { BreakPointName, BreakPoints } from 'app/shared/theme/common/typings';

export const breakPoints: BreakPoints = {
  mobile: 0,
  tablet: 481,
  smallDesktop: 901,
  largeDesktop: 1601,
};

type Media = {
  [key in keyof BreakPoints]: (...args: any) => any;
};

export const media = Object.keys(breakPoints).reduce(
  (acc: Media, label: string) => {
    acc[label as BreakPointName] = (...args: any) => css`
    @media (min-width: ${breakPoints[label as BreakPointName]}px) {
      ${css.apply(undefined, args)};
    }
  `;
    return acc;
  },
  {} as Media
);
