export const get = (value: any, path: string, defaultValue: any) => {
  return String(path)
    .split('.')
    .reduce((accumulator, val) => {
      try {
        if (!accumulator[val] && accumulator[val] !== undefined) {
          return accumulator[val];
        }

        accumulator = accumulator[val] || defaultValue;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return defaultValue;
      }
      return accumulator;
    }, value);
};

export const getInteger = (value: any, path: string, defaultValue: any) => {
  return String(path)
    .split('.')
    .reduce((accumulator, val) => {
      try {
        if (!accumulator[val] && accumulator[val] !== undefined) {
          return accumulator[val];
        }

        accumulator =
          accumulator[val] || accumulator[val] == 0
            ? accumulator[val]
            : defaultValue;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return defaultValue;
      }
      return accumulator;
    }, value);
};
