import React, { useState } from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import {
  Icon,
  InputHelperText,
  InputProps,
  TextInput,
} from 'app/shared/components/atoms';
import { Box, Typography } from 'app/shared/components/atoms';
import { ReactComponent as AlertCircle } from 'icons/alert-circle.svg';
import { ReactComponent as Show } from 'icons/eye.svg';
import { ReactComponent as Hide } from 'icons/eye-off.svg';
interface PasswordFieldProps extends InputProps {
  helperText?: string | React.ReactNode | null;
  label: string;
  isDisabled?: boolean;
  labelColor?: string;
  isRequired?: boolean;
  'data-qaid'?: string;
}

export const PasswordField: React.FC<
  React.PropsWithChildren<PasswordFieldProps>
> = ({
  onChange,
  helperText,
  hasError = false,
  label = 'Password',
  isSuccessful = false,
  value = '',
  isDisabled = false,
  className,
  backgroundColor,
  labelColor,
  invertColors,
  mb,
  isRequired,
  'data-qaid': qaId = 'password',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const icon = showPassword ? Hide : Show;
  const fieldType = showPassword ? 'text' : 'password';
  const action = showPassword ? 'HIDE' : 'SHOW';

  const { colors, ruler } = useCurrentTheme();

  const onClickShowHide = (e: any) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <Box
      position="relative"
      w="100%"
      flexDirection="column"
      alignItems="stretch"
      className={className}
      data-qaid={qaId}
      mb={mb}
    >
      <Typography
        lineHeight="100%"
        fontSize="fontSize50"
        fontWeight="semibold"
        textTransform="uppercase"
        mb={2}
        color={hasError ? colors.messaging.error.dark : labelColor}
        data-qaid={`${qaId}-label`}
      >
        {label}
        {isRequired ? '*' : ''}
      </Typography>
      <Box flexDirection="row" alignItems="center">
        <Box
          flexDirection="row"
          position="absolute"
          right={`${ruler[4]}px`}
          gap={1}
        >
          <Box
            b="none"
            backgroundColor="unset"
            onClick={onClickShowHide}
            alignContent="center"
            alignItems="center"
            gap={2}
            p={0}
            cursor="pointer"
          >
            <Icon
              size="md"
              icon={icon}
              strokeColor={
                invertColors
                  ? colors.primary.white.base
                  : colors.primary.black.base
              }
              data-qaid={`${qaId}-show-hide-icon`}
            />
            <Typography fontSize="fontSize50" lineHeight="100%">
              {action}
            </Typography>
          </Box>
          {hasError && (
            <Icon
              icon={AlertCircle}
              strokeColor={
                colors.messaging.error[invertColors ? 'light' : 'dark']
              }
              fillColor="transparent"
              size="md"
              data-qaid={`${qaId}-status-icon`}
            />
          )}
        </Box>
        <TextInput
          type={fieldType}
          disabled={isDisabled}
          hasError={hasError}
          value={value}
          onChange={onChange}
          backgroundColor={backgroundColor}
          invertColors={invertColors}
          aria-required={isRequired}
          data-qaid={`${qaId}-input`}
          {...props}
        />
      </Box>
      {helperText && (
        <InputHelperText
          isDisabled={isDisabled}
          hasError={hasError}
          isSuccessful={isSuccessful}
          invertColors={invertColors}
          data-qaid={`${qaId}-helper-text`}
        >
          {helperText}
        </InputHelperText>
      )}
    </Box>
  );
};
