import { useEffect } from 'react';

import { useAnalyticsContext } from './useAnalyticsContext';

export const useTrackAnalyticsOnLoad = (
  eventDescription: string,
  options: {
    [key: string]: string | undefined;
  },
  conditional = true
) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();

  useEffect(() => {
    if (conditional) {
      trackAnalyticsEvent(eventDescription, options, true);
    }
  }, [trackAnalyticsEvent, eventDescription, conditional, options]);
};
