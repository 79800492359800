import { CommonTheme, commonTheme } from './common';
import {
  adminManualCSSTheme,
  ManualCSSTheme,
  manualCSSTheme,
} from './manualCSS';

// Maps theme name (which we use as an alias in code so we don't have to import the theme
// itself everywhere) to the theme object itself
export const themeCatalog: {
  [name: string]: CommonTheme | ManualCSSTheme;
} = {
  common: commonTheme,
  manualCSS: manualCSSTheme,
  adminManualCSS: adminManualCSSTheme,
};
