import React from 'react';

import { FontSizePropKey } from 'app/shared/theme';
import { Typography } from 'app/shared/components/atoms';

export const ModalTitle: React.FC<
  React.PropsWithChildren<{
    title: string;
    fontSize?: FontSizePropKey;
    disableClose?: boolean;
    'data-qaid'?: string;
  }>
> = ({
  title,
  fontSize = 'fontSize500',
  'data-qaid': qaId,
  disableClose = false,
}) => {
  return (
    <Typography
      data-qaid={qaId}
      fontSize={fontSize}
      fontWeight="medium"
      fontFamily="title"
      lineHeight="120%"
      mr={disableClose ? 0 : [13, 13, 16, 16]}
    >
      {title}
    </Typography>
  );
};
