import React, { useContext, useEffect } from 'react';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';

import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { OnSignUpSuccess } from 'app/shared/typings';
import { CreateGoogleSession } from 'app/shared/graphql/sessions/mutationHooks';
import { AlertContext, useAuthFormContext } from 'app/shared/context';

import { AccountCreatedSuccess } from './AccountCreatedSuccess';
import {
  ThirdPartyAuthButton,
  ThirdPartyAuthMode,
} from './ThirdPartyAuthButton';

const googleGrey = '#747775';

interface Props {
  onSuccess: OnSignUpSuccess;
  onError?: (e: any) => void;
  mode: ThirdPartyAuthMode;
  matchingEmail?: string;
  isGuestCheckoutOrderComplete?: boolean;
}

export const GoogleLoginButton: React.FC<React.PropsWithChildren<Props>> = ({
  onSuccess,
  onError,
  mode,
  matchingEmail,
  isGuestCheckoutOrderComplete = false,
}) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const [loginUser, { data }] = CreateGoogleSession();
  const alertContext = useContext(AlertContext);
  const { setThirdPartyAuthProvider } = useAuthFormContext();
  const theme = useCurrentTheme();

  const onButtonClick = useGoogleLogin({
    onSuccess: async (tokenResponse: TokenResponse) => {
      setThirdPartyAuthProvider('Google');
      try {
        loginUser({
          variables: {
            accessToken: tokenResponse.access_token,
            matchingEmail,
            isGuestCheckoutOrderComplete,
          },
        });
      } catch (e) {
        onError && onError(e);
      }
    },
  });

  useEffect(() => {
    const sessionId = data?.createGoogleSession?.sessionId;
    const isNewAccount = data?.createGoogleSession?.isNewAccount;
    const user = data?.createGoogleSession?.user;

    if (sessionId && user) {
      if (isNewAccount) {
        alertContext.addAlert({
          type: 'success',
          message: <AccountCreatedSuccess user={user} />,
          id: 'account-created-snackbar',
        });
      }
      onSuccess({ user });
    }
  }, [data?.createGoogleSession]);

  const dataQaid =
    mode === ThirdPartyAuthMode.SIGN_UP
      ? 'google-signup-button'
      : 'google-login-button';

  useEffect(() => {
    trackAnalyticsEvent(
      'Google Oauth Displayed',
      {
        oauth: dataQaid,
      },
      true
    );
  });

  return (
    <ThirdPartyAuthButton
      backgroundColor={theme.colors.primary.white.lightest}
      borderColor={googleGrey}
      color={theme.colors.primary.black.base}
      onClick={() => onButtonClick()}
      data-qaid={dataQaid}
      provider="Google"
    />
  );
};

export default GoogleLoginButton;
