import React, { useState } from 'react';

import { commonTheme as theme } from 'app/shared/theme';
import { Box } from 'app/shared/components/atoms';
import { Snackbar, SnackbarType } from 'app/shared/components/molecules';

interface Alert {
  message: React.ReactNode;
  type: SnackbarType;
  time: number;
  id: number | string;
}

interface AddAlertProps {
  message: React.ReactNode;
  type: SnackbarType;
  timeout?: number; // in milliseconds
  id?: string; // will also be used as the Snackbar's data-qaid
}

interface AlertContextValues {
  alerts: Alert[];
  addAlert: ({ message, type, timeout, id }: AddAlertProps) => void;
  clearAlerts: () => void;
}

export const AlertContext = React.createContext<AlertContextValues>({
  alerts: [],
  addAlert: () => {},
  clearAlerts: () => {},
});

const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const removeAlert = (alertId: number | string) => {
    setAlerts(alerts.filter((a: Alert) => a.id !== alertId));
  };

  // Unfortunately, apollo client adds some additional formatting to the error
  // message that ApolloError/ApolloClient does not allow us to override/control
  // at this moment in time
  // (https://github.com/apollographql/apollo-feature-requests/issues/46#issuecomment-528046940).
  // As a simplified workaround, we are going to manually remove it from the error
  // message string if it exists.
  const GRAPHQL_ERROR_HEADER = 'GraphQL error: ';
  const GENERAL_ERROR_HEADER = 'Error: ';
  const removeGraphqlHeader = (errorMessage: React.ReactNode) =>
    typeof errorMessage === 'string'
      ? errorMessage
          .replace(GRAPHQL_ERROR_HEADER, '')
          .replace(GENERAL_ERROR_HEADER, '')
      : errorMessage;

  function addAlert({ message, type, timeout, id }: AddAlertProps) {
    const existing = alerts.find((a: Alert) => a.message === message);

    if (!existing) {
      const formattedMessage = removeGraphqlHeader(message);
      const newMessage = {
        message: formattedMessage,
        type,
        time: new Date().getTime(),
        id: id || Math.random(),
      };

      setAlerts([...alerts, newMessage]);

      if (timeout) {
        setTimeout(() => {
          removeAlert(newMessage.id);
        }, timeout);
      }
    }
  }

  function clearAlerts() {
    setAlerts([]);
  }

  const contextValue = {
    alerts,
    addAlert,
    clearAlerts,
  };

  if (typeof window === 'object') {
    return (
      <AlertContext.Provider value={contextValue}>
        {children}
        <Box
          alignItems="center"
          w="100vw"
          zIndex={theme.zIndex.snackbar}
          gap={2}
          flexDirection="column"
          position="fixed"
          top="0px"
          right="0px"
          mt={[4, 8, 12, 12]}
        >
          {alerts.map((a: Alert) => (
            <Snackbar
              key={a.id}
              data-qaid={typeof a.id === 'string' ? a.id : undefined}
              isOpen={true}
              closeSnackbar={() => removeAlert(a.id)}
              type={a.type}
            >
              {a.message}
            </Snackbar>
          ))}
        </Box>
      </AlertContext.Provider>
    );
  }

  return null;
};

export default AlertProvider;
