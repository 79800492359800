import React from 'react';
import styled from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import { useLinkClickBehavior } from 'app/shared/utils/useLinkClickBehavior';
import {
  withButtonStyleCSS,
  WithButtonStyleProps,
  withDimensionsCSS,
  WithDimensionsProps,
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';
import { Icon } from 'app/shared/components/atoms';
import { NavigationLinkProps } from 'app/shared/components/atoms';

export interface NavigationButtonProps
  extends NavigationLinkProps,
    WithSpacingProps,
    WithDimensionsProps,
    WithButtonStyleProps {
  trailingIcon?: React.FC<
    React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
  >;
  leadingIcon?: React.FC<
    React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
  >;
}

interface NavigationButtonCSSProps extends NavigationButtonProps {
  theme: CommonTheme;
}

const StyledLink = styled.a<NavigationButtonCSSProps>`
  ${withButtonStyleCSS};
  ${withSpacingCSS};
  ${withDimensionsCSS};
  flex-direction: row;
`;

export const NavigationButton = ({
  children,
  openInNewTab,
  href,
  onClick,
  target,
  rel,
  trailingIcon,
  leadingIcon,
  ...props
}: NavigationButtonProps) => {
  const { anchorRef, handleClick, newRel, newTarget } = useLinkClickBehavior({
    openInNewTab,
    href,
    onClick,
    target,
    rel,
  });

  return (
    <StyledLink
      ref={anchorRef}
      onClick={handleClick}
      rel={newRel}
      target={newTarget}
      href={href}
      {...props}
    >
      {leadingIcon && <Icon size="sm" icon={leadingIcon} />}
      {children}
      {trailingIcon && <Icon size="md" icon={trailingIcon} />}
    </StyledLink>
  );
};
