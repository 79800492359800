import React from 'react';
import { createPortal } from 'react-dom';

import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils';
import { getPosition } from 'app/shared/utils/usePosition';
import { Popper } from 'app/shared/components/atoms';
import { Box } from 'app/shared/components/atoms';

interface Props {
  isSearchable?: boolean;
  optionsLength: number;
  selectRef: React.RefObject<any>;
  invertColors?: boolean;
}

export const OptionsContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isSearchable,
  optionsLength,
  selectRef,
  invertColors,
}) => {
  const { isMobile } = useIsBreakpointSize();
  const { colors, borderRadius } = useCurrentTheme();

  const sharedContainerProps = {
    backgroundColor: invertColors
      ? colors.primary.black.base
      : colors.primary.white.lightest,
    w: `calc(${selectRef.current?.clientWidth}px + 2px)`,
    h: 'fit-content',
    maxHeight: '300px',
  };

  /*
    The Popper component doesn't behave as expected on mobile browsers when an input
    is focused and the soft keyboard is triggered, displaying the select options in the wrong position.
    This alternative uses a dumber component that requires less calculations and it's a bit less flexible, in an attempt to give mobile users a better experience when using searchable select
    fields.
  */
  if (isMobile && isSearchable) {
    const anchorRefPositionAttributes = getPosition(selectRef.current);

    return (
      <Box
        position="absolute"
        zIndex={20}
        style={{
          top: `${
            (selectRef as any).current.offsetTop +
            anchorRefPositionAttributes.height
          }px`,
          width: `${anchorRefPositionAttributes.width}px`,
        }}
      >
        <Box data-qaid="select-options" {...sharedContainerProps}>
          {children}
        </Box>
      </Box>
    );
  }

  const sharedPopperProps = {
    anchorEl: selectRef,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    keepInViewPort: false,
    flip: false,
    showDiamond: false,
    reactToChange: optionsLength,
  };

  if (isSearchable) {
    return createPortal(
      <Popper {...sharedPopperProps} offset={{ vertical: 4 }}>
        <Box
          data-qaid="select-options"
          bt={colors.accent.primaryAccent.base}
          bw="2px"
          {...sharedContainerProps}
        >
          {children}
        </Box>
      </Popper>,
      document.body
    );
  }

  return createPortal(
    <Popper {...sharedPopperProps} offset={{ vertical: 0 }}>
      <Box
        data-qaid="select-options"
        br={colors.accent.primaryAccent.base}
        bb={colors.accent.primaryAccent.base}
        bl={colors.accent.primaryAccent.base}
        borderRadiusTopLeft="0"
        borderRadiusTopRight="0"
        borderRadiusBottomLeft={borderRadius.input}
        borderRadiusBottomRight={borderRadius.input}
        {...sharedContainerProps}
      >
        {children}
      </Box>
    </Popper>,
    document.body
  );
};
