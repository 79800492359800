import React from 'react';
import { useMutation } from '@apollo/client';
import { Field, Formik, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import {
  boolean as YupBoolean,
  object as YupObject,
  string as YupString,
} from 'yup';

import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { OnSignUpSuccess, RenderCompetitionOptIn } from 'app/shared/typings';
import { CREATE_SESSION } from 'app/shared/graphql/sessions/mutations';
import { useAuthFormContext } from 'app/shared/context';
import { Box, Typography } from 'app/shared/components/atoms';
import {
  ActionLink,
  NavigationLink as Link,
} from 'app/shared/components/atoms';
import {
  AuthApiError,
  PasswordField,
  Textfield,
} from 'app/shared/components/molecules';
import {
  ModalButtonsSection,
  ModalTitle,
} from 'app/shared/components/molecules';
import {
  ThirdPartyAuth,
  ThirdPartyAuthMode,
} from 'app/shared/components/organisms';

interface LoginProps {
  onSuccess: OnSignUpSuccess;
  onCancel?: () => void;
  onError?: () => void;
  renderCompetitionOptIn?: RenderCompetitionOptIn;
  onClickSignUp?: () => void;
  'data-qaid'?: string;
  testMode?: boolean;
  testResponse?: any;
  disableClose?: boolean;
}

interface LoginFormValues {
  email: string;
  password: string;
  competitionDataSharingOptIn?: boolean;
}

export const LoginForm: React.FC<React.PropsWithChildren<LoginProps>> = ({
  onSuccess,
  onError,
  renderCompetitionOptIn,
  onClickSignUp,
  testMode,
  testResponse,
  'data-qaid': qaId = 'login-form',
  disableClose,
}) => {
  const { formatMessage } = useIntl();
  const { isCheckoutAuth, apiError, setApiError } = useAuthFormContext();
  const { isMobile } = useIsBreakpointSize();

  const [loginUser] = useMutation(CREATE_SESSION);

  const onSubmit = async (
    values: LoginFormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    const { email, password, competitionDataSharingOptIn } = values;
    try {
      let status = await loginUser({
        variables: { email, password },
      });

      if (status?.data?.createSession?.sessionId) {
        setSubmitting(false);
        const { user } = status.data.createSession;
        onSuccess({ user, competitionDataSharingOptIn });
      }
    } catch (e) {
      setSubmitting(false);
      if (e instanceof Error) {
        setApiError(e);
        onError && onError();
      }
    }
  };

  const loginFormSchema = YupObject().shape({
    email: YupString()
      .email(formatMessage({ id: 'form.validations.email.invalid' }))
      .required(formatMessage({ id: 'form.validations.email.required' })),
    password: YupString().required(
      `${formatMessage({ id: 'form.validations.password.required' })}`
    ),
    competitionDataSharingOptIn: YupBoolean().nullable(),
  });

  return (
    <Box flexDirection="column" gap={8} pb={12}>
      <Box flexDirection="column" gap={4}>
        <ModalTitle
          disableClose={disableClose}
          title={formatMessage({ id: 'authentication.login.header' })}
        />
        {onClickSignUp && (
          <Typography>
            {formatMessage({ id: 'authentication.signIn.dontHaveAnAccount' })}{' '}
            <ActionLink
              data-qaid="authentication-signup-link"
              onClick={onClickSignUp}
              fontWeight="medium"
              variant="secondary"
            >
              {formatMessage({ id: 'authentication.signIn.createOne' })}
            </ActionLink>
          </Typography>
        )}
      </Box>
      <Formik
        initialValues={{
          email: '',
          password: '',
          competitionDataSharingOptIn: false,
        }}
        validationSchema={loginFormSchema}
        onSubmit={onSubmit}
      >
        {(formikProps: FormikProps<any>) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          } = formikProps;

          return (
            <form data-qaid={qaId} onSubmit={handleSubmit}>
              <Box flexDirection="column" gap={8} mb={8}>
                <Field
                  as={Textfield}
                  data-qaid="login-form-email"
                  label={formatMessage({ id: 'shared.emailAddress' })}
                  name="email"
                  type="email"
                  autoCapitalize="off"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.email && touched.email && String(errors.email)
                  }
                  hasError={!!errors.email && touched.email}
                  touched={!!touched.email}
                  isRequired={true}
                />
                <Field
                  as={PasswordField}
                  data-qaid="login-form-password"
                  label={formatMessage({ id: 'form.fields.password' })}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.password &&
                    touched.password &&
                    String(errors.password)
                  }
                  hasError={!!errors.password && touched.password}
                  touched={!!touched.password}
                  isRequired={true}
                />
                {!!renderCompetitionOptIn &&
                  renderCompetitionOptIn({ formikProps, authType: 'Log In' })}
                {apiError && (
                  <AuthApiError
                    genericIntlId="authentication.error.genericLoginError"
                    onClickSignUp={onClickSignUp}
                  />
                )}
              </Box>
              <Box alignItems="center" w="100%" flexDirection="column" gap={4}>
                {(!isCheckoutAuth || !isMobile) && (
                  <ModalButtonsSection
                    primaryCtaDataQaid="login-form-submit"
                    primaryCtaText={formatMessage({
                      id: 'authentication.signIn',
                    })}
                    isPrimaryCtaLoading={isSubmitting}
                    primaryCtaType="submit"
                    isPrimaryCtaFullWidth={true}
                  />
                )}
                {!isCheckoutAuth && (
                  <Link
                    href="/auth/secret/new"
                    textDecoration="underline !important"
                    fontWeight="medium"
                  >
                    {formatMessage({ id: 'authentication.forgotPassword' })}
                  </Link>
                )}
              </Box>
            </form>
          );
        }}
      </Formik>
      <ThirdPartyAuth
        onSuccess={onSuccess}
        onError={onError}
        mode={ThirdPartyAuthMode.SIGN_IN}
        testMode={testMode}
        testResponse={testResponse}
      />
    </Box>
  );
};

export default LoginForm;
