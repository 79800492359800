import React from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { Divider, ListItem, ListItemProps } from 'app/shared/components/atoms';
import { DropdownSectionHeader } from 'app/shared/components/atoms';
import { Box } from 'app/shared/components/atoms';

import { OptionsContainer } from './Container';
import { GroupedOptions } from './Grouped';
import { SimpleOptions } from './Simple';

export interface SharedOptionsProps {
  options: any[];
  getOptionLabel?: (option: any) => string;
  optionValueKey?: string;
  renderOption?: (option: any, props: any) => React.ReactNode;
  invertColors?: boolean;
}

export interface NoOptionsOption {
  label: string;
  onClick?: () => void;
}

export interface SharedSelectProps extends SharedOptionsProps {
  isLoading?: boolean;
  hasError?: boolean;
  noOptionsMessage?: string;
  groupBy?: (option: any) => string;
  recommendedOptions?: any;
  getRecommendedOptionsTitle?: (options: any) => string;
  mostVisitedOptions?: any;
  getMostVisitedOptionsTitle?: (options: any) => string;
  isSearchable?: boolean;
  userIsSearching?: boolean;
  noOptionsOption?: NoOptionsOption;
  'data-qaid'?: string;
}

interface Props extends SharedSelectProps, React.ComponentPropsWithoutRef<any> {
  isOpen: boolean;
  selectRef: React.RefObject<any>;
  onOptionClick: (value: any, option: any) => void;
}

const defaultNoOptionsOption: NoOptionsOption = {
  label: 'No options...',
  onClick: undefined,
};

export const Option: React.FC<React.PropsWithChildren<ListItemProps>> = (
  props
) => {
  return (
    <ListItem
      fontSize="fontSize100"
      lineHeight="100%"
      px={4}
      py={3}
      {...props}
    />
  );
};

export const Options: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  isLoading = false,
  hasError = false,
  options,
  noOptionsMessage,
  onOptionClick,
  getOptionLabel,
  optionValueKey,
  getRecommendedOptionsTitle,
  getMostVisitedOptionsTitle,
  recommendedOptions,
  mostVisitedOptions,
  renderOption,
  groupBy,
  isSearchable,
  userIsSearching,
  'data-qaid': qaId,
  noOptionsOption = defaultNoOptionsOption,
  invertColors,
  selectRef,
}) => {
  const { colors } = useCurrentTheme();
  const { formatMessage } = useIntl();

  const showRecommendedOptions =
    recommendedOptions !== undefined &&
    recommendedOptions.length > 0 &&
    !userIsSearching;
  const showMostVisitedOptions =
    mostVisitedOptions !== undefined &&
    mostVisitedOptions.length > 0 &&
    !userIsSearching;

  if (!isOpen) {
    return null;
  }

  const selectedOption = selectRef.current?.value;

  function renderContent() {
    if (hasError) {
      return (
        <Option isDisabled isLastChild invertColors={invertColors}>
          {formatMessage({ id: 'typeahead.errorPleaseTryAgain' })}
        </Option>
      );
    }

    if (isLoading) {
      return (
        <Option isDisabled isLastChild invertColors={invertColors}>
          {formatMessage({ id: 'typeahead.loadingOptions' })}
        </Option>
      );
    }

    if (
      (showRecommendedOptions || showMostVisitedOptions) &&
      !userIsSearching
    ) {
      return (
        <Box overflowY="scroll" maxHeight="inherit">
          {showRecommendedOptions && (
            <Box as="ul" p={0} m={0}>
              <DropdownSectionHeader
                data-qaid={`${qaId}-recommended-header`}
                invertColors={invertColors}
              >
                {getRecommendedOptionsTitle
                  ? getRecommendedOptionsTitle(recommendedOptions)
                  : `Recommended ${recommendedOptions.length}`}
              </DropdownSectionHeader>
              <SimpleOptions
                options={recommendedOptions}
                getOptionLabel={getOptionLabel}
                onOptionClick={onOptionClick}
                renderOption={renderOption}
                invertColors={invertColors}
                selectedOption={selectedOption}
              />
            </Box>
          )}
          {showRecommendedOptions && showMostVisitedOptions && (
            <Divider
              mt={0}
              mb={0}
              color={
                invertColors
                  ? colors.primary.black.light
                  : colors.accent.primaryAccent.base
              }
            />
          )}
          {showMostVisitedOptions && (
            <Box as="ul" p={0} m={0}>
              <DropdownSectionHeader
                data-qaid={`${qaId}-popular-header`}
                invertColors={invertColors}
              >
                {getMostVisitedOptionsTitle
                  ? getMostVisitedOptionsTitle(mostVisitedOptions)
                  : `Top ${mostVisitedOptions.length}`}
              </DropdownSectionHeader>
              <SimpleOptions
                options={mostVisitedOptions}
                getOptionLabel={getOptionLabel}
                onOptionClick={onOptionClick}
                renderOption={renderOption}
                invertColors={invertColors}
                selectedOption={selectedOption}
              />
            </Box>
          )}
        </Box>
      );
    }

    if ((!options || options.length === 0) && isSearchable && userIsSearching) {
      const onNoOptionsClick = noOptionsOption.onClick;
      return (
        <Option
          onClick={onNoOptionsClick}
          isDisabled={!onNoOptionsClick}
          isLastChild
          invertColors={invertColors}
        >
          {noOptionsOption.label}
        </Option>
      );
    }

    if (isSearchable && (!options || options.length === 0)) {
      return (
        <Option isDisabled isLastChild invertColors={invertColors}>
          {noOptionsMessage
            ? noOptionsMessage
            : formatMessage({ id: 'typeahead.noResults' })}
        </Option>
      );
    }

    if (groupBy) {
      return (
        <GroupedOptions
          options={options}
          onOptionClick={onOptionClick}
          groupBy={groupBy}
          getOptionLabel={getOptionLabel}
          invertColors={invertColors}
          selectedOption={selectedOption}
          data-qaid={qaId}
        />
      );
    }

    return (
      <Box as="ul" p={0} m={0} overflowY="scroll" maxHeight="inherit">
        <SimpleOptions
          onOptionClick={onOptionClick}
          getOptionLabel={getOptionLabel}
          options={options}
          optionValueKey={optionValueKey}
          invertColors={invertColors}
          selectedOption={selectedOption}
        />
      </Box>
    );
  }

  return (
    <OptionsContainer
      isSearchable={isSearchable}
      selectRef={selectRef}
      optionsLength={options.length}
      invertColors={invertColors}
    >
      {renderContent()}
    </OptionsContainer>
  );
};
