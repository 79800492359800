import { useCallback, useEffect, useState } from 'react';

import { useAnalyticsContext } from 'app/shared/utils';
import { City, GetCitiesData } from 'app/shared/typings/cities';
import { GetCitiesForCitySelectorLazy } from 'app/shared/graphql/cities/queryHooks';

const CITY_SEARCH_DEBOUNCE_TIME_MS = 300;

export const useCitiesSearchOnInput = ({
  citySearchSessionID,
  citySearchInput = '',
  showAllCitiesOnMount = false,
  showInactiveCities = false,
  filterBy,
  orderBy = 'title',
  orderDirection = 'asc',
}: {
  citySearchInput?: string;
  citySearchSessionID?: string;
  showAllCitiesOnMount?: boolean;
  showInactiveCities?: boolean;
  filterBy?: (option: City, query: string) => boolean;
  orderBy?: string;
  orderDirection?: string;
}) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const [debounceLoading, setDebounceLoading] = useState(false);

  const onGetCitiesCompleted = useCallback(
    (data: GetCitiesData) => {
      if (showAllCitiesOnMount) {
        return; // Don't send tracking event when it's not a user initiated search
      }
      // Apply the same filter that is applied for dropdown results
      const filteredCities = data.cities.cities.filter((city: City) => {
        if (filterBy) {
          return filterBy(city, citySearchInput);
        }
        return true;
      });
      trackAnalyticsEvent('City Selector Search Executed', {
        city_search_session_id: citySearchSessionID,
        search_term: citySearchInput,
        search_results: filteredCities.map((city) => city.title),
        number_of_results: filteredCities.length,
      });
    },
    [
      citySearchInput,
      citySearchSessionID,
      trackAnalyticsEvent,
      filterBy,
      showAllCitiesOnMount,
    ]
  );

  const loadCitiesDefaultVariables = showInactiveCities
    ? { orderBy, orderDirection, isLiteQuery: true, activeStatus: 'all' }
    : { orderBy, orderDirection, isLiteQuery: true };

  const [loadCities, citiesResponse] = GetCitiesForCitySelectorLazy({
    notifyOnNetworkStatusChange: false,
    onCompleted: onGetCitiesCompleted,
  });

  useEffect(() => {
    if (showAllCitiesOnMount) {
      loadCities({
        variables: loadCitiesDefaultVariables,
      });
    }
  }, [loadCities, orderBy, orderDirection, showAllCitiesOnMount]);

  useEffect(() => {
    let timerId: number;
    if (citySearchInput.length > 0 && !showAllCitiesOnMount) {
      setDebounceLoading(true);
      timerId = window.setTimeout(() => {
        loadCities({
          variables: Object.assign(loadCitiesDefaultVariables, {
            citySearch: citySearchInput,
          }),
        });
        setDebounceLoading(false);
      }, CITY_SEARCH_DEBOUNCE_TIME_MS);
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
        setDebounceLoading(false);
      }
    };
  }, [citySearchInput, loadCities, showAllCitiesOnMount]);

  return {
    ...citiesResponse,
    loading: citiesResponse.loading || debounceLoading,
  };
};
