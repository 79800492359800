import React, { createContext, useMemo, useState } from 'react';

interface AuthFormContextProviderProps {
  isCheckoutAuth: boolean;
  isCompleteSignUpFlow?: boolean;
  isPlusOneFlow?: boolean;
}

export interface AuthFormContextValue {
  isCheckoutAuth: boolean;
  isCompleteSignUpFlow: boolean;
  isPlusOneFlow: boolean;
  setFormSubmitAction: (func: Function) => void;
  setIsSubmitting: (set: boolean) => void;
  formSubmitAction: () => void;
  isSubmitting: boolean;
  apiError?: Error;
  setApiError: (error?: Error) => void;
  thirdPartyAuthProvider?: 'Apple' | 'Facebook' | 'Google';
  setThirdPartyAuthProvider: (
    provider: 'Apple' | 'Facebook' | 'Google'
  ) => void;
}

export const AuthFormContext = createContext<AuthFormContextValue | null>(null);

export const AuthFormContextProvider: React.FC<
  React.PropsWithChildren<AuthFormContextProviderProps>
> = ({
  isCheckoutAuth,
  isCompleteSignUpFlow = false,
  isPlusOneFlow = false,
  children,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formSubmitAction, setFormSubmitAction] = useState<any>(() => {});
  const [apiError, setApiError] = useState<Error | undefined>(undefined);
  const [thirdPartyAuthProvider, setThirdPartyAuthProvider] = useState<
    'Apple' | 'Facebook' | 'Google' | undefined
  >();

  const authFormContextValue = useMemo(
    () => ({
      isSubmitting,
      setIsSubmitting,
      formSubmitAction,
      setFormSubmitAction,
      isCheckoutAuth,
      isCompleteSignUpFlow,
      isPlusOneFlow,
      apiError,
      setApiError,
      thirdPartyAuthProvider,
      setThirdPartyAuthProvider,
    }),
    [
      formSubmitAction,
      isCheckoutAuth,
      isCompleteSignUpFlow,
      isPlusOneFlow,
      isSubmitting,
      apiError,
      thirdPartyAuthProvider,
    ]
  );

  return (
    <AuthFormContext.Provider value={authFormContextValue}>
      {children}
    </AuthFormContext.Provider>
  );
};

export const useAuthFormContext = () => {
  const context = React.useContext(AuthFormContext);
  if (context === null) {
    throw new Error(
      'useAuthFormContext must be used within a AuthFormContextProvider'
    );
  }
  return context;
};
