import { routesConfig } from 'src/routesConfig';

import { removeDuplicateAndEmptyStrings } from 'app/shared/utils/string';
import { AdminNavChildMenu, AdminNavParentMenu } from 'app/shared/typings';

import { adminNavConfig } from './adminNavConfig';

const pathsToPermissions = routesConfig.reduce(
  (
    obj: {
      [key: string]: string;
    },
    route: any
  ) => {
    obj[route.path] = route.permission;
    return obj;
  },
  {}
);

const getMenuItemPermissionFromRoutesConfig = (path: string) =>
  pathsToPermissions[path];

export const addAdminNavMenuItemPermissions = (rawAdminNavConfig: any) => {
  return rawAdminNavConfig.map((parentMenuItem: AdminNavParentMenu) =>
    parentMenuItem.childMenu
      ? Object.assign(parentMenuItem, {
          childMenu: parentMenuItem.childMenu.map(
            (childMenuItem: AdminNavChildMenu) =>
              Object.assign(childMenuItem, {
                permission:
                  childMenuItem.permission == 'usePermissionForLink' &&
                  childMenuItem.link
                    ? getMenuItemPermissionFromRoutesConfig(childMenuItem.link)
                    : childMenuItem.permission || null,
              })
          ),
        })
      : parentMenuItem
  );
};

export const getPermissionNamesForAdminNavbar = (
  additionalPermissionNames?: string[]
) => {
  const rawAdminNavConfig = adminNavConfig({});

  const rawAdminNavConfigWithActualPermissions =
    addAdminNavMenuItemPermissions(rawAdminNavConfig);

  const childMenuPermissionLists = rawAdminNavConfigWithActualPermissions.map(
    (c: AdminNavParentMenu) =>
      c.childMenu
        ? c.childMenu.map((m: AdminNavChildMenu) => m.permission || '')
        : []
  );
  const childMenuPermissions: string[] = ([] as string[]).concat(
    ...childMenuPermissionLists
  );
  const fullPermissions = childMenuPermissions.concat(
    additionalPermissionNames || []
  );

  return removeDuplicateAndEmptyStrings(fullPermissions);
};

export const getPermissionsContextsForAdminNavbar = (
  additionalContexts?: string[]
) =>
  removeDuplicateAndEmptyStrings(
    getPermissionNamesForAdminNavbar()
      .map((p: string) => (p ? p.split('.')[0] : ''))
      .concat(additionalContexts || [])
  );
