import { useMutation } from '@apollo/client';

import { User } from 'app/shared/typings';

import {
  CREATE_APPLE_SESSION,
  CREATE_FACEBOOK_SESSION,
  CREATE_GOOGLE_SESSION,
} from './mutations';

interface CreateSessionNestedData {
  sessionId: string;
  isNewAccount: boolean;
  user: User;
}

export interface CreateAppleSessionData {
  createAppleSession: CreateSessionNestedData;
}
export const CreateAppleSession = () =>
  useMutation<CreateAppleSessionData>(CREATE_APPLE_SESSION);

export interface CreateFacebookSessionData {
  createFacebookSession: CreateSessionNestedData;
}
export const CreateFacebookSession = () =>
  useMutation<CreateFacebookSessionData>(CREATE_FACEBOOK_SESSION);

export interface CreateGoogleSessionData {
  createGoogleSession: CreateSessionNestedData;
}
export const CreateGoogleSession = () =>
  useMutation<CreateGoogleSessionData>(CREATE_GOOGLE_SESSION);
