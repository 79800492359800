import { IntlShape } from 'react-intl';
import { object as YupObject, string as YupString } from 'yup';

import { emailValidationRegex } from 'app/shared/utils/emailValidation';

interface Props {
  formatMessage: IntlShape['formatMessage'];
}

export const SignUpFormSchema = ({ formatMessage }: Props) =>
  YupObject().shape({
    email: YupString()
      .matches(emailValidationRegex, {
        message: formatMessage(
          {
            id: 'form.fieldMustBeValidEmail',
          },
          {
            field: 'Email',
          }
        ),
      })
      .required(formatMessage({ id: 'form.validations.email.required' })),
    password: YupString()
      .min(8, `${formatMessage({ id: 'form.validations.password.length' })}`)
      .required(
        `${formatMessage({ id: 'form.validations.password.required' })}`
      ),
    city: YupObject().nullable(),
    mobile: YupString()
      .matches(/^[-\d().+]+( [-\d.]+)*$/, {
        message: formatMessage({
          id: 'form.validations.mobile.invalid',
        }),
      })
      .nullable(),
  });
