import styled from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import {
  withBorderCSS,
  WithBorderProps,
  withFontCSS,
  WithFontProps,
  withInputStyleCSS,
  WithInputStyleProps,
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';

export interface InputProps
  extends React.ComponentPropsWithRef<'input'>,
    WithInputStyleProps,
    WithSpacingProps,
    WithBorderProps,
    WithFontProps {}

interface InputCSSProps extends InputProps {
  theme: CommonTheme;
}

export const TextInput = styled.input<InputCSSProps>`
  ${withInputStyleCSS};
  ${withSpacingCSS};
  ${withFontCSS};
  ${withBorderCSS};
`;
