export const SOFAR_PRIVACY_POLICY_URL =
  'https://www.sofarsounds.com/privacy_policy';
export const SOFAR_TERMS_AND_CONDITIONS_URL =
  'https://www.sofarsounds.com/terms_and_conditions';
export const SOFAR_MOBILE_TERMS_URL =
  'https://www.sofarsounds.com/mobile_terms_and_conditions';
export const VISA_OFFER_TERMS_URL =
  'https://www.sofarsounds.com/visaoffer/terms-and-conditions';
export const PRIVATE_RELAY_HELP_URL =
  'https://help.sofarsounds.com/hc/en-us/articles/29137811036177-I-used-Hide-My-Email-with-Apple-will-I-still-get-emails-about-my-order';
export const NEW_HELP_REQUEST_URL =
  'https://help.sofarsounds.com/hc/en-us/requests/new';
export const ACCESSIBILITY_HELP_ARTICLE_URL =
  'https://help.sofarsounds.com/hc/en-us/articles/360005498218-Where-can-I-find-accessibility-information-for-a-show'; // TODO: double check this url before removing 'accessibility' property and adding the new related ones
