import React from 'react';
import loadable from '@loadable/component';
import { Route, Routes } from 'react-router-dom';
import { defaultRouteConfig, RouteInfo, routesConfig } from 'src/routesConfig';
import { getRouteImport } from 'src/routingUtils';

import {
  withAuthCheck,
  withBeamer,
  withHotjar,
  withLocaleMessages,
  withOptinMonster,
  withPermissionsCheck,
  withTheme,
  withUserAgreements,
} from 'app/shared/hoc';
import { ThemeNameOrFunction } from 'app/shared/hoc/withTheme';

export interface RouteData {
  path: string;
  component: any;
}

interface GetRouteProps {
  path: string;
  componentPath: string;
  require?: string[];
  postAuthReturnToPath?: string;
  permission?: string;
  permissionContexts?: string[];
  themeName: ThemeNameOrFunction;
  localeMessages?: string;
}

const getRoute = ({
  path,
  componentPath,
  require = [],
  postAuthReturnToPath = undefined,
  permission = undefined,
  themeName,
  localeMessages = 'index',
}: GetRouteProps): RouteData => {
  let component: any = loadable(() => getRouteImport(componentPath));

  // IMPORTANT: The order of calls below is VERY IMPORTANT - the component must be wrapped
  // with some hoc's before others, or things don't work (since some higher order components
  // depend on other higher order components)
  if (require.includes('auth')) {
    component = withAuthCheck(component, postAuthReturnToPath);
  }
  if (require.includes('permissions')) {
    component = withPermissionsCheck(component, permission);
  }
  component = withTheme(component, themeName);
  component = withLocaleMessages(component, localeMessages);
  if (require.includes('userAgreements')) {
    component = withUserAgreements(component, permission);
  }
  if (require.includes('optinMonster')) {
    component = withOptinMonster(component);
  }
  if (require.includes('hotjar')) {
    component = withHotjar(component);
  }
  if (require.includes('beamer')) {
    component = withBeamer(component);
  }

  return {
    path,
    component,
  };
};

const getRoutesDataList = () => {
  const routesDataList = routesConfig.map((routeInfo: RouteInfo) => {
    return getRoute({
      path: routeInfo.path,
      componentPath: routeInfo.componentPath,
      require: routeInfo.require,
      postAuthReturnToPath: routeInfo.postAuthReturnToPath,
      permission: routeInfo.permission,
      themeName: routeInfo.themeName,
      localeMessages: routeInfo.localeMessages,
    });
  });

  routesDataList.unshift(getRoute(defaultRouteConfig));

  return routesDataList;
};

export const RenderRoutes = () => {
  const routesDataList = getRoutesDataList();

  return (
    <Routes>
      {routesDataList.map(({ path, component }: any) => {
        return (
          <Route
            key={path ? path : 'prismic'}
            path={path}
            element={React.createElement(component)}
          />
        );
      })}
    </Routes>
  );
};
