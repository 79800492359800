import React, { useState } from 'react';

import { useAnalyticsContext } from 'app/shared/utils';
import useOptinMonster from 'app/shared/utils/useOptinMonster';
import { ModalStep } from 'app/shared/typings';
import {
  AuthFormContextProvider,
  MultiStepSignUpContextProvider,
} from 'app/shared/context';
import ThemeWrapper from 'app/shared/components/atoms/ThemeWrapper';
import { MultiStepSignUpWizard } from 'app/shared/components/organisms';

const WizardSteps: { [key: string]: number } = {
  [ModalStep.COMPLETE_ACCOUNT]: 1,
  [ModalStep.SMS_OPT_IN]: 2,
};

const OptInMonsterSignUpWizard = ({
  userEmail,
  isCompleteAccountModalOpen,
  setIsCompleteAccountModalOpen,
}: {
  userEmail?: string;
  isCompleteAccountModalOpen: boolean;
  setIsCompleteAccountModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalStep, setModalStep] = useState<ModalStep>(
    ModalStep.COMPLETE_ACCOUNT
  );
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const onSignUpSuccess = () => {
    trackAnalyticsEvent('OIM Sign Up Form Modal Account Created', {});
  };

  return (
    <MultiStepSignUpWizard
      formContext="OIM"
      isOpen={isCompleteAccountModalOpen}
      setIsOpen={setIsCompleteAccountModalOpen}
      onClose={() => {
        setIsCompleteAccountModalOpen(false);
      }}
      onSignUpSuccess={onSignUpSuccess}
      userEmail={userEmail}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setModalStep={setModalStep}
      activeStep={WizardSteps[modalStep]}
      modalStep={modalStep}
    />
  );
};

function withOptinMonster(
  PassedComponent: React.ComponentType<React.PropsWithChildren<any>>
) {
  const WrappedComponent = (props: any) => {
    const { trackAnalyticsEvent } = useAnalyticsContext();
    const [leadEmail, setLeadEmail] = useState<string | undefined>();
    const [isCompleteAccountModalOpen, setIsCompleteAccountModalOpen] =
      useState(false);

    const onOptinMonsterSuccess = (event: any) => {
      trackAnalyticsEvent('OIM Sign Up Form Modal Displayed', {});
      const leadEmail = event?.detail?.Optin?.data?.fields?.email;
      setLeadEmail(leadEmail);
      setIsCompleteAccountModalOpen(true);
    };
    useOptinMonster(onOptinMonsterSuccess);

    return (
      <>
        <ThemeWrapper themeName="common">
          <MultiStepSignUpContextProvider>
            <AuthFormContextProvider
              isCheckoutAuth={false}
              isCompleteSignUpFlow={true}
              isPlusOneFlow={false}
            >
              <OptInMonsterSignUpWizard
                userEmail={leadEmail}
                isCompleteAccountModalOpen={isCompleteAccountModalOpen}
                setIsCompleteAccountModalOpen={setIsCompleteAccountModalOpen}
              />
            </AuthFormContextProvider>
          </MultiStepSignUpContextProvider>
        </ThemeWrapper>
        <PassedComponent {...props} />
      </>
    );
  };

  return WrappedComponent;
}

export default withOptinMonster;
