import React from 'react';
import styled, { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import { WithChildSVGProps } from 'app/shared/components/styleUtils';
import {
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';
import { ReactComponent as MonogramLogo } from 'icons/sofar-logos/monogram.svg';
import { ReactComponent as MonogramLogoFilled } from 'icons/sofar-logos/monogram-filled.svg';
import { ReactComponent as PrimaryLogo } from 'icons/sofar-logos/primary.svg';

export interface LogoProps
  extends React.ComponentPropsWithRef<any>,
    WithSpacingProps {
  variant: 'primary' | 'monogram' | 'monogramFilled';
  fillColor: string;
  height: number;
}

interface LogoWrapperCSSProps extends WithChildSVGProps {
  theme: CommonTheme;
}

const LogoWrapper = styled.span<LogoWrapperCSSProps>`
  ${({ svgFillColor }) => css`
    > svg {
      stroke: none !important;
      fill: ${svgFillColor};
      path {
        stroke: none !important;
        fill: ${svgFillColor};
      }
      overflow: visible;
    }
  `}
  height: fit-content;
  display: flex;
  ${withSpacingCSS};
`;

export const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({
  variant,
  fillColor,
  height,
  ...props
}) => {
  const icon: React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> =
    variant === 'primary'
      ? PrimaryLogo
      : variant === 'monogram'
        ? MonogramLogo
        : MonogramLogoFilled;

  const width = variant === 'primary' ? height * 2.125 : height;

  return (
    <LogoWrapper svgFillColor={fillColor} svgStrokeColor="none" {...props}>
      {React.createElement(icon || 'span', { height, width })}
    </LogoWrapper>
  );
};
