import { css } from 'styled-components';

import {
  ManualCSSTheme,
  MediaManualCSS,
  TShirtSizeBreakpointName,
  TShirtSizeBreakpoints,
} from 'app/shared/theme/manualCSS/typings';

// breakpoints define the minimum width of a device (>=)
const breakPoints: TShirtSizeBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1052,
  xl: 1200,
};

export const manualCSSTheme: ManualCSSTheme = {
  name: 'manualCSS',
  globalStyleName: 'ManualCSSGlobalStyle',

  isFanFacing: true,

  breakPoints,

  fonts: {
    regular: 'futura-pt, sans-serif',
    primaryFallback: 'Georgia, serif',
  },

  colors: {
    primary: '#D1A03F',
    primaryActive: '#DFBD79',
    primaryHover: '#DFBD79',
    primaryText: '#1C1C1C',
    secondaryText: '#1C1C1C',
    invertedSecondaryText: '#D1A03F',
    disabledText: '#1C1C1C',
    disabledBackground: '#8D8D8D',
    darkBorder: '#1C1C1C',
    focusedBackground: '#DDBEE026',

    // blacks & greys
    black: '#1C1C1C',
    backToBlack: '#1C1C1C',
    blackBetty: '#1C1C1C',
    paintItBlack: '#333333',
    darkSmoke: '#A29992',
    blueSmoke: '#949494',
    lightBlueSmoke: '#A29992',
    macyGrey: '#E5E5E5',
    darkGrey: '#A29992',
    silverSprings: '#E5E5E5',
    whiteDenim: '#FFFFFF',
    warmGrey: '#A29992',

    // green variations
    green900: '#D1A03F',
    green800: '#D1A03F',
    green700: '#D1A03F',
    green600: '#D1A03F',
    green500: '#D1A03F',
    green400: '#D1A03F',
    green300: '#D2F5F2',
    green200: '#D1A03F',
    green100: '#D1A03F',
    green50: '#D1A03F',

    // additional
    greenOnions: '#DFBD79',
    greenGarden: '#D1A03F',
    blueChristmas: '#D1A03F',
    purpleHaze: '#D1A03F',
    purplePills: '#D1A03F',
    checkBerry: '#FF8080',
    blueMagic: '#A29992',
    blueGrey: '#a7bdd4',
    brownSugar: '#9B525F',
    pinkTriangle: '#FF8080',
    royalBlood: '#FF8080',
    redRedWine: '#A60000',
    peaches: '#F99973',
    orangeCrush: '#FEC907',
    mellowYellow: '#FEC907',
    yellowSubmarine: '#FEC907',
  },
  fontSizes: {
    base: { lg: '20px', xs: '18px' },
    title: { lg: '75px', md: '40px', xs: '40px' },
    h1: { lg: '75px', md: '40px', xs: '40px' },
    h2: { lg: '54px', md: '37px', xs: '37px' },
    h3: { lg: '45px', md: '34px', xs: '34px' },
    h4: { lg: '37px', md: '31px', xs: '31px' },
    h5: { lg: '31px', md: '26px', xs: '26px' },
    h6: { lg: '14px', md: '14px', xs: '14px' },
    bodyTitle: { lg: '26px', md: '18px', xs: '18px' },
    body1: { lg: '20px', xs: '18px' },
    body2: { lg: '18px', xs: '16px' },
    body3: { lg: '16px', xs: '14px' },
    overline: { lg: '16px', xs: '14px' },
    caption: { lg: '14px', xs: '14px' },
    button: { lg: '20px', xs: '18px' },
    formGroupLabel: '18px',
    tooltip: '10px',
    selectOption: '14px',
  },

  fontColors: {
    primary: '#1C1C1C',
    secondary: '#333333',
  },

  fontStyles: {
    links: {
      textDecoration: 'none!important',
      fontWeight: 'bold',
      fontFamily: 'futura-pt, sans-serif',
    },
    title: {
      letterSpacing: '2px',
      fontFamily: 'bely-display, Georgia, serif',
    },
    h1: {
      lineHeight: '120%',
      textTransform: 'none',
      letterSpacing: '2px',
      fontFamily: 'bely-display, Georgia, serif',
    },
    h2: {
      lineHeight: '120%',
      textTransform: 'none',
      letterSpacing: '0px',
      fontFamily: 'bely-display, Georgia, serif',
    },
    h3: {
      lineHeight: '120%',
      textTransform: 'none',
      letterSpacing: '2px',
      fontFamily: 'bely-display, Georgia, serif',
    },
    h4: {
      lineHeight: '120%',
      textTransform: 'none',
      letterSpacing: '0px',
      fontFamily: 'bely-display, Georgia, serif',
    },
    h5: {
      lineHeight: '120%',
      textTransform: 'none',
      letterSpacing: '0px',
      fontFamily: 'bely-display, Georgia, serif',
    },
    h6: {
      lineHeight: '120%',
      textTransform: 'none',
      letterSpacing: '2px',
      fontFamily: 'bely-display, Georgia, serif',
    },
    body: {
      lineHeight: '140%',
      letterSpacing: '0.5px',
    },
    body2: {
      lineHeight: '140%',
      letterSpacing: '0.5px',
    },
    caption: {
      lineHeight: '120%',
      letterSpacing: '0.5px',
    },
    formGroupLabel: {
      letterSpacing: '0.5px',
    },
    button: {
      fontWeight: 'normal',
    },
  },

  media: Object.keys(breakPoints).reduce((acc, label: string) => {
    acc[label as keyof TShirtSizeBreakpoints] = (...args: any) => css`
      @media (min-width: ${breakPoints[label as TShirtSizeBreakpointName]}px) {
        ${css.apply(undefined, args)};
      }
    `;
    return acc;
  }, {} as MediaManualCSS),

  ruler: Array.from({ length: 28 }, (_e, i) => i * 4),
  zIndex: {
    navbar: 100,
  },
  dimensions: {
    buttonHeight: '44px',
    radioBox: '20px',
    containerWidth: {
      small: '600px',
      default: '800px',
      large: '1000px',
      fullscreen: '100%',
    },

    button: {
      small: {
        height: undefined, // In fan facing button height is determined by content and padding not set explicitly
        padding: '9px 15px',
      },

      default: {
        height: undefined, // In fan facing button height is determined by content and padding not set explicitly
        padding: '14px 25px',
      },
    },

    navbarHeight: {
      lg: '88px',
      md: '130px',
      xs: '102px',
      xsNoCitySelector: '60px',
      mdNoCitySelector: '60px',
    },

    container: {
      padding: '0 20px',
      paddingSm: '0 20px',
      maxWidthSm: '540px',
      maxWidthMd: '740px',
      maxWidthLg: '960px',
      maxWidthXl: '1160px',
      innerMargin: '0',
    },

    menuItem: {
      padding: '10px 16px',
    },

    input: {
      height: '44px',
    },
  },

  borderRadius: {
    default: '0',
    large: '0',
    button: '100px',
    buttonSmall: '4px',
    input: '0',
  },

  utils: {
    transition: (attr?: string) => `${attr || 'all'} .2s ease`,
  },
};
