import { useContext } from 'react';

import { useAnalyticsContext } from 'app/shared/utils';
import { snakeCaseToCamel } from 'app/shared/utils/string';
import useForceVariants from 'app/shared/utils/useForceVariants';
import {
  FeatureFlagEnabled,
  GetFeatureFlags,
} from 'app/shared/graphql/featureFlags/queryHooks';
import { FeatureFlagsContext } from 'app/shared/context/FeatureFlags';

const isFeatureFlagEnabled = (
  featureFlagName: string,
  featureFlagEnabled: boolean,
  featureFlagForceVariants?: any
) => {
  if (
    featureFlagForceVariants &&
    featureFlagForceVariants[featureFlagName] !== undefined
  ) {
    return featureFlagForceVariants[featureFlagName];
  }

  return featureFlagEnabled;
};

const getFeatureFlagsJustAskedFor = (flags?: string[]) =>
  flags?.map((flag: string) => snakeCaseToCamel(flag)) || [];

type FeatureFlagsEnabled = {
  [name: string]: boolean;
};

// Returns object of feature flags in camelCase and boolean values
const getFeatureFlagsEnabled = (
  featureFlagsJustLoaded?: FeatureFlagEnabled[],
  featureFlagForceVariants?: any,
  featureFlagsAlreadyEnabled?: FeatureFlagsEnabled,
  flags?: string[]
) => {
  const featureFlagsEnabled: FeatureFlagsEnabled = {};

  if (featureFlagsJustLoaded) {
    featureFlagsJustLoaded.forEach((featureFlagResult: any) => {
      if (featureFlagResult.name) {
        featureFlagsEnabled[featureFlagResult.name] = isFeatureFlagEnabled(
          featureFlagResult.name,
          featureFlagResult.featureFlagEnabled,
          featureFlagForceVariants
        );
      }
    });
  }

  if (featureFlagsAlreadyEnabled) {
    Object.keys(featureFlagsAlreadyEnabled).forEach((featureFlag: string) => {
      if (!flags || getFeatureFlagsJustAskedFor(flags).includes(featureFlag)) {
        featureFlagsEnabled[featureFlag] =
          featureFlagsAlreadyEnabled[featureFlag];
      }
    });
  }

  return featureFlagsEnabled;
};

// Returns feature flags in camelCase
const useFeatureFlagsAlreadyLoadedInfo = () => {
  const featureFlagsContext = useContext(FeatureFlagsContext);
  return {
    featureFlagsAlreadyLoaded: featureFlagsContext?.featureFlagsLoaded || [],
    featureFlagsAlreadyEnabled: featureFlagsContext?.featureFlagsEnabled || {},
  };
};

// Returns feature flags in snake_case
const getFeatureFlagsToLoad = (
  flags?: string[],
  featureFlagsAlreadyLoaded?: string[]
) => {
  if (!flags || flags.length == 0) {
    return [];
  }

  const flagsNotLoaded = featureFlagsAlreadyLoaded
    ? flags.filter(
        (flag: string) =>
          !featureFlagsAlreadyLoaded?.includes(snakeCaseToCamel(flag))
      )
    : flags;

  return flagsNotLoaded;
};

export const useFeatureFlags = (flags: string[]) => {
  const featureFlagForceVariants = useForceVariants(flags);

  const { featureFlagsAlreadyLoaded, featureFlagsAlreadyEnabled } =
    useFeatureFlagsAlreadyLoadedInfo();

  const featureFlagsToLoad = getFeatureFlagsToLoad(
    flags,
    featureFlagsAlreadyLoaded
  );

  const { data, error, loading, called } = GetFeatureFlags({
    flags: featureFlagsToLoad,
    shouldLoadFlags: featureFlagsToLoad.length > 0,
  });

  const featureFlagsEnabled = getFeatureFlagsEnabled(
    data?.featureFlags,
    featureFlagForceVariants,
    featureFlagsAlreadyEnabled,
    flags
  );

  return {
    featureFlagsEnabled,
    error,
    loading,
    called,
    queryRequired: !!(featureFlagsToLoad.length > 0),
  };
};

export const useFeatureFlagsForExperiments = (flags?: string[]) => {
  const { anonymousId: flipperId, segmentInitialized } = useAnalyticsContext();

  const featureFlagForceVariants = useForceVariants(flags);

  const { featureFlagsAlreadyLoaded, featureFlagsAlreadyEnabled } =
    useFeatureFlagsAlreadyLoadedInfo();

  const featureFlagsToLoad = getFeatureFlagsToLoad(
    flags,
    featureFlagsAlreadyLoaded
  );

  const { data, error, loading, called } = GetFeatureFlags({
    flags: featureFlagsToLoad,
    flipperId,
    shouldLoadFlags:
      featureFlagsToLoad.length > 0 && !!(flipperId && segmentInitialized),
  });

  const featureFlagsEnabled = getFeatureFlagsEnabled(
    data?.featureFlags,
    featureFlagForceVariants,
    featureFlagsAlreadyEnabled,
    flags
  );

  return {
    featureFlagsEnabled,
    error,
    loading,
    called,
    queryRequired: !!(featureFlagsToLoad.length > 0),
  };
};
