import React from 'react';
import { useIntl } from 'react-intl';

import { ActionLink, Icon } from 'app/shared/components/atoms';
import { ReactComponent as ChevronLeft } from 'icons/chevron-left.svg';

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}
export const GoBack: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ActionLink
      variant="secondary"
      textTransform="uppercase"
      textDecoration="none"
      fontWeight="semibold"
      flexDirection="row"
      alignItems="center"
      lineHeight="100%"
      onClick={onClick}
    >
      <Icon
        icon={ChevronLeft}
        size="sm"
        fillColor="none !important"
        w="auto"
        mr={2}
      />
      {formatMessage({ id: 'shared.goBack' })}
    </ActionLink>
  );
};
