import React from 'react';
import { FormattedMessage } from 'react-intl';

import { isApplePrivateRelayEmail } from 'app/shared/utils/isApplePrivateRelayEmail';
import { PRIVATE_RELAY_HELP_URL } from 'app/shared/utils/siteUrls';
import { User } from 'app/shared/typings';
import { NavigationLink, Typography } from 'app/shared/components/atoms';

export const AccountCreatedSuccess = ({
  user,
  isAppleAuth = false,
}: {
  user: User;
  isAppleAuth?: boolean;
}) => {
  if (isAppleAuth && isApplePrivateRelayEmail(user.email)) {
    return (
      <FormattedMessage
        id="authentication.thirdPartyAuthSuccessAppleRelay"
        values={{
          a: (chunks) => (
            <NavigationLink
              openInNewTab
              isSuccessMessage
              href={PRIVATE_RELAY_HELP_URL}
              fontSize="fontSize75"
            >
              {chunks}
            </NavigationLink>
          ),
        }}
      />
    );
  }

  return (
    <FormattedMessage
      id="authentication.thirdPartyAuthSuccess"
      values={{
        email: user.email,
        a: (chunks) => (
          <NavigationLink
            openInNewTab
            isSuccessMessage
            href="/profile/edit"
            fontSize="fontSize75"
          >
            {chunks}
          </NavigationLink>
        ),
        b: (chunks) => (
          <Typography
            as="span"
            display="inline"
            fontWeight="bold"
            fontSize="fontSize75"
            color="inherit"
          >
            {chunks}
          </Typography>
        ),
      }}
    />
  );
};
