import { ApolloClient, createHttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';

import config from 'app/lib/config';

import 'isomorphic-fetch';

let urlToken = '';

if (typeof window !== 'undefined') {
  urlToken = window.location.search
    .replace(/.*token/, 'token')
    .replace(/token=([^&]+).*/, '$1');
}

const httpLink = createHttpLink({
  // Append the operation name onto the GQL URL for understandability in logging when the POST
  // body is not present or not easily matched to the POST.
  uri: (operation) =>
    `${config.graphqlEndpoint}?on=${encodeURIComponent(
      operation.operationName
    )}`,
  credentials: 'include',
  headers: {
    token: urlToken,
  },
});

const cache =
  typeof window !== 'undefined'
    ? new InMemoryCache({
        // typePolicies and related logic is to handle ArtistEvents component on ArtistDashboard,
        // which renders both a list of events and a list of featured sets, which might
        // occasionally have records with the same id (since they're separate db tables), which breaks
        // the page by suppressing one or the other of the records with the duplicate id, because
        // Apollo normally caches based on id - here, we are telling Apollo to cache ArtistEvent
        // records by both eventType and id, and cache everything else by id (normal behavior)
        typePolicies: {
          ArtistEvent: {
            keyFields: ['eventType', 'id'],
          },
        },
      }).restore((window as any).__APOLLO_STATE__)
    : new InMemoryCache();

export default new ApolloClient({
  link: httpLink,
  connectToDevTools: typeof window !== 'undefined',
  ssrMode:
    process.env.NODE_ENV === 'development' && typeof window === 'undefined',
  resolvers: {},
  cache,
});
