import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import {
  AuthFormType,
  City,
  ModalStep,
  OnSignUpSuccess,
  RenderCompetitionOptIn,
} from 'app/shared/typings';
import {
  AuthFormContextProvider,
  MultiStepSignUpContextProvider,
  useAuthFormContext,
  useMultiStepSignUpContext,
} from 'app/shared/context';
import { AuthContext } from 'app/shared/context/Auth';
import { Box } from 'app/shared/components/atoms';
import { Modal } from 'app/shared/components/molecules';
import { MultiStepSignUpWizard } from 'app/shared/components/organisms';

import { LoginForm } from './LoginForm';

interface Props {
  onSuccess: OnSignUpSuccess;
  onError?: () => void;
  currentTabSelected: AuthFormType;
  setCurrentTabSelected: React.Dispatch<React.SetStateAction<AuthFormType>>;
  renderCompetitionOptIn?: RenderCompetitionOptIn;
  parentContainer: string;
  eventId?: string;
  city?: City;
  signUpHeader?: string;
  preventClose?: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disableClose?: boolean;
  portalRootId?: string;
  isCheckoutAuth?: boolean;
  testMode?: boolean;
  testResponse?: any;
  embedInPage?: boolean;
}

const WizardSteps: { [key: string]: number } = {
  [ModalStep.CREATE_ACCOUNT]: 1,
  [ModalStep.SMS_OPT_IN]: 2,
};

const AuthModal: React.FC<React.PropsWithChildren<Props>> = ({
  onSuccess,
  onError,
  renderCompetitionOptIn,
  currentTabSelected,
  setCurrentTabSelected,
  parentContainer,
  eventId,
  city,
  signUpHeader,
  isOpen,
  setIsOpen,
  disableClose = false,
  portalRootId,
  testMode,
  testResponse,
  embedInPage = false,
}) => {
  const intl = useIntl();
  const { isMobile } = useIsBreakpointSize();
  const { colors, borderRadius } = useCurrentTheme();
  const modalRef = useRef<HTMLDivElement>(null);
  const { user: loggedInUser, signIn } = useContext(AuthContext);
  const [trackedModalOpened, setTrackedModalOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { setApiError } = useAuthFormContext();
  const { newUser, setNewUser, smsMarketingSignUpForm } =
    useMultiStepSignUpContext();
  const user = loggedInUser || newUser;
  const [modalStep, setModalStep] = useState<ModalStep>(
    user ? ModalStep.SMS_OPT_IN : ModalStep.CREATE_ACCOUNT
  );

  useEffect(() => {
    if (isOpen && !trackedModalOpened) {
      trackAnalyticsEvent(
        'Auth Modal Opened',
        {
          path: location.pathname,
          modal_tab: currentTabSelected,
          modal_context: parentContainer,
          event_id: eventId,
          city_slug: city?.cachedSlug,
        },
        true
      );
      setTrackedModalOpened(true);
    }
  }, [
    isOpen,
    trackedModalOpened,
    currentTabSelected,
    parentContainer,
    eventId,
    city,
  ]);

  const onClickSignIn = () => {
    setApiError(undefined);
    if (modalRef && modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
    setCurrentTabSelected('signIn' as AuthFormType);
  };

  const onClickSignUp = () => {
    setApiError(undefined);
    if (modalRef && modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
    setCurrentTabSelected('signUp' as AuthFormType);
  };

  const { shouldDisplaySmsOptIn } = smsMarketingSignUpForm({
    formContext: 'auth-modal',
  });

  useEffect(() => {
    if (shouldDisplaySmsOptIn) {
      setModalStep(ModalStep.SMS_OPT_IN);
    } else if (newUser) {
      onSuccess({
        user: newUser,
        competitionDataSharingOptIn,
      });
    }
  }, [newUser]);
  const [competitionDataSharingOptIn, setCompetitionDataSharingOptIn] =
    useState(false);

  const onSignUpSuccess: OnSignUpSuccess = (signUpSuccessParams) => {
    if (signUpSuccessParams) {
      if (signUpSuccessParams.isNewAccount) {
        trackAnalyticsEvent('Sign Up Form Modal Account Created', {
          form_context: parentContainer,
        });
      }
      setCompetitionDataSharingOptIn(
        !!signUpSuccessParams.competitionDataSharingOptIn
      );
      if (setNewUser) {
        setNewUser(signUpSuccessParams.user);
      } else {
        onSuccess(signUpSuccessParams);
      }
    }
  };

  const onCloseLoginForm = () => {
    setIsOpen(false);
    trackAnalyticsEvent('Modal Closed By User', {
      modal: 'auth-modal-login',
      is_mobile: isMobile,
      title: intl.formatMessage({ id: 'authentication.login.header' }),
      primary_cta_text: intl.formatMessage({ id: 'authentication.signIn' }),
    });
  };

  const onCloseMultiStepSignUpWizard = () => {
    setIsOpen(false);
    if (modalStep === ModalStep.CREATE_ACCOUNT) {
      trackAnalyticsEvent('Modal Closed By User', {
        modal: 'auth-modal-create-account',
        is_mobile: isMobile,
        title: intl.formatMessage({ id: 'shared.signUp' }),
        primary_cta_text: intl.formatMessage({
          id: 'shared.signUp',
        }),
      });
    } else {
      trackAnalyticsEvent('Modal Closed By User', {
        modal: 'auth-modal-sms-opt-in',
        is_mobile: isMobile,
        title: intl.formatMessage({ id: 'shared.smsSignUp.title' }),
        primary_cta_text: intl.formatMessage({ id: 'shared.signUpForSms' }),
        secondary_cta_text: intl.formatMessage({ id: 'shared.skipForNow' }),
      });
      newUser && onSuccess({ user: newUser, competitionDataSharingOptIn });
      signIn();
    }
  };

  const modalContent = () => {
    if (currentTabSelected === 'signIn') {
      return (
        <LoginForm
          renderCompetitionOptIn={renderCompetitionOptIn}
          onSuccess={onSuccess}
          onError={onError}
          onClickSignUp={onClickSignUp}
          data-qaid="auth-modal-login-form"
          testMode={testMode}
          testResponse={testResponse}
          disableClose={disableClose}
        />
      );
    }

    return (
      <MultiStepSignUpWizard
        formContext="auth-modal-sign-up"
        createAccountTitle={signUpHeader}
        onSignUpSuccess={onSignUpSuccess}
        activeStep={WizardSteps[modalStep]}
        modalStep={modalStep}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        city={city}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setModalStep={setModalStep}
        onClickSignIn={onClickSignIn}
        renderCompetitionOptIn={renderCompetitionOptIn}
        competitionDataSharingOptIn={competitionDataSharingOptIn}
        renderModal={false}
        onClose={onCloseMultiStepSignUpWizard}
        onSuccess={onSuccess}
        testMode={testMode}
        testResponse={testResponse}
      />
    );
  };

  if (embedInPage) {
    return (
      <Box
        p={[0, 8, 8, 8]}
        h="fit-content"
        borderRadius={borderRadius.modal}
        backgroundColor={colors.primary.white.base}
        maxWidth="480px"
      >
        {modalContent()}
      </Box>
    );
  }

  return (
    <Modal
      data-qaid="auth-modal"
      portalRootId={portalRootId}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      maxWidth="480px"
      disableClose={disableClose}
      modalRef={modalRef}
      isFullscreenMobile={true}
      onClose={
        currentTabSelected === 'signIn'
          ? onCloseLoginForm
          : onCloseMultiStepSignUpWizard
      }
    >
      {modalContent()}
    </Modal>
  );
};

const AuthModalWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  isCheckoutAuth = false,
  ...props
}) => (
  <AuthFormContextProvider
    isCheckoutAuth={isCheckoutAuth}
    isPlusOneFlow={false}
    isCompleteSignUpFlow={false}
  >
    <MultiStepSignUpContextProvider>
      <AuthModal {...props} />
    </MultiStepSignUpContextProvider>
  </AuthFormContextProvider>
);

export default AuthModalWrapper;
