import { createGlobalStyle, css } from 'styled-components';

import { CommonTheme } from './typings';

export const CommonGlobalStyle = createGlobalStyle<{ theme: CommonTheme }>`
  ${({ theme }: { theme: CommonTheme }) => css`
    html {
      box-sizing: border-box;
      ${theme.media.mobile`
        font-size: 18px;
      `};
      ${theme.media.smallDesktop`
        font-size: 20px;
      `};
    }
    body {
      color: ${theme.colors.primary.black.base};
      margin: 0px;
    }
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    * {
      font-family: ${theme.fonts.regular};
    }
    *,
    ::after,
    ::before {
      box-sizing: inherit;
    }
    a {
      color: ${theme.colors.primary.black.base};
    }

    .grecaptcha-badge {
      visibility: hidden;
    }
  `}
`;
