import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { CommonTheme, FontSizeResponsive, FontSizes } from 'app/shared/theme';
import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import {
  withBorderCSS,
  WithBorderProps,
} from 'app/shared/components/styleUtils/withBorderCSS';
import {
  withCursorCSS,
  WithCursorProps,
} from 'app/shared/components/styleUtils/withCursorCSS';
import {
  withDimensionsCSS,
  WithDimensionsProps,
} from 'app/shared/components/styleUtils/withDimensionsCSS';
import {
  withFlexCSS,
  WithFlexProps,
} from 'app/shared/components/styleUtils/withFlexCSS';
import {
  withPositionCSS,
  WithPositionProps,
} from 'app/shared/components/styleUtils/withPositionCSS';
import {
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils/withSpacingCSS';

interface SharedIconProps
  extends React.ComponentPropsWithRef<any>,
    WithSpacingProps,
    WithDimensionsProps,
    WithBorderProps,
    WithCursorProps,
    WithPositionProps,
    WithFlexProps {
  icon?: React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
  strokeColor?: string;
  fillColor?: string;
  backgroundColor?: string;
  hoverFillColor?: string;
  hoverStrokeColor?: string;
  focusFillColor?: string;
  focusStrokeColor?: string;
  activeFillColor?: string;
  activeStrokeColor?: string;
  display?: string;
  alignItems?: string;
  useNativeColors?: boolean;
  transform?: string;
  transition?: string;
  spin?: boolean;
  pointerEvents?: string;
}

export interface IconProps extends SharedIconProps {
  size: keyof FontSizes['icon'];
}

export interface IconCSSProps extends SharedIconProps {
  theme: CommonTheme;
}

const rotate360 = keyframes`
  from {
    transform: scaleX(-1) rotate(360deg);
  }
  to {
    transform: scaleX(-1) rotate(0deg);
  }
`;

export const IconWrapper = styled.span<IconCSSProps>`
  ${({
    theme,
    strokeColor,
    fillColor,
    backgroundColor,
    hoverFillColor,
    hoverStrokeColor,
    focusFillColor,
    focusStrokeColor,
    activeFillColor,
    activeStrokeColor,
    display,
    useNativeColors = false,
    transform,
    transition,
    spin = false,
    pointerEvents,
  }) => css`
    display: ${display ? display : 'inline-block'};
    border: none;
    line-height: 100%;
    ${
      !useNativeColors &&
      css`
        background: ${backgroundColor ? backgroundColor : undefined};
        stroke: ${strokeColor || theme.colors.primary.black.base};
        fill: ${fillColor || 'none'};
        path {
          stroke: ${strokeColor || theme.colors.primary.black.base};
          fill: ${fillColor || 'none'};
        }
      `
    }
    > svg {
      overflow: visible;
    }

    &:hover {
      ${
        hoverFillColor &&
        css`
          fill: ${hoverFillColor};
          path {
            fill: ${hoverFillColor};
          }
        `
      }
      ${
        hoverStrokeColor &&
        css`
          stroke: ${hoverStrokeColor};
          path {
            stroke: ${hoverStrokeColor};
          }
        `
      }
    }

    &:focus {
      ${
        focusFillColor &&
        css`
          fill: ${focusFillColor};
          path {
            fill: ${focusFillColor};
          }
        `
      }
      ${
        focusStrokeColor &&
        css`
          stroke: ${focusStrokeColor};
          path {
            stroke: ${focusStrokeColor};
          }
        `
      }
    }

    &:active {
      ${
        activeFillColor &&
        css`
          fill: ${activeFillColor};
          path {
            fill: ${activeFillColor};
          }
        `
      }
      ${
        activeStrokeColor &&
        css`
          stroke: ${activeStrokeColor};
          path {
            stroke: ${activeStrokeColor};
          }
        `
      }
    }

    ${transform && `transform: ${transform};`}
    ${transition && `transition: ${transition};`}
    ${
      spin &&
      css`
        animation: ${rotate360} infinite 1500ms linear;
      `
    }

    ${pointerEvents && `pointer-events: ${pointerEvents};`}
  `}

  ${withFlexCSS};
  ${withSpacingCSS};
  ${withDimensionsCSS};
  ${withBorderCSS};
  ${withCursorCSS};
  ${withPositionCSS};
`;

export const Icon: React.FC<React.PropsWithChildren<IconProps>> = ({
  icon,
  strokeColor,
  fillColor,
  backgroundColor,
  size,
  display,
  alignItems = 'center',
  w,
  ...props
}) => {
  const { isDesktop } = useIsBreakpointSize();
  const { fontSizes } = useCurrentTheme();
  const fontSizeResponsive: FontSizeResponsive = fontSizes.icon[size];

  const width =
    w == 'auto'
      ? undefined
      : isDesktop
        ? fontSizeResponsive.desktop
        : fontSizeResponsive.mobile;
  const height = isDesktop
    ? fontSizeResponsive.desktop
    : fontSizeResponsive.mobile;

  return (
    <IconWrapper
      {...props}
      strokeColor={strokeColor}
      fillColor={fillColor}
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      alignItems={alignItems}
      justifyContent="center"
      display={display}
    >
      {icon && React.createElement(icon, { width, height })}
    </IconWrapper>
  );
};
