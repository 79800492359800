import React, { useContext, useEffect, useRef } from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { useScript } from 'app/shared/utils/useScript';
import { OnSignUpSuccess } from 'app/shared/typings';
import { CreateAppleSession } from 'app/shared/graphql/sessions/mutationHooks';
import { AlertContext, useAuthFormContext } from 'app/shared/context';
import { Box } from 'app/shared/components/atoms';

import { AccountCreatedSuccess } from './AccountCreatedSuccess';
import {
  ThirdPartyAuthButton,
  ThirdPartyAuthMode,
} from './ThirdPartyAuthButton';

interface Props {
  onSuccess: OnSignUpSuccess;
  onError?: (e: any) => void;
  mode: ThirdPartyAuthMode;
  matchingEmail?: string;
  isGuestCheckoutOrderComplete?: boolean;
}

declare const AppleID: any;

export const AppleLoginButton: React.FC<React.PropsWithChildren<Props>> = ({
  onSuccess,
  onError,
  mode,
  matchingEmail,
  isGuestCheckoutOrderComplete = false,
}) => {
  const scriptStatus = useScript(
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
  );
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { colors } = useCurrentTheme();
  const { setThirdPartyAuthProvider } = useAuthFormContext();
  const ref = useRef<HTMLDivElement>(null);
  const alertContext = useContext(AlertContext);
  const [loginUser, { data }] = CreateAppleSession();

  const appleAuthButtonQAID =
    mode === ThirdPartyAuthMode.SIGN_IN
      ? 'apple-login-button'
      : 'apple-signup-button';

  function handleClick(e: any) {
    if (e) {
      e.preventDefault();
      ref.current?.click();
    }
  }

  function initAppleSignIn() {
    const redirectUrl = `${location.protocol}//${location.host}`;
    AppleID.auth.init({
      clientId: 'com.sofarsounds.web',
      scope: 'name email',
      redirectURI: redirectUrl,
      usePopup: true,
    });
  }

  async function handleAppleIDSignInOnSuccess(event: any) {
    setThirdPartyAuthProvider('Apple');
    console.log(
      'AppleSignInSuccess event' + JSON.stringify(event?.detail?.user)
    );
    const response = event.detail;
    try {
      await loginUser({
        variables: {
          idToken: response.authorization.id_token,
          firstName: response.user?.name?.firstName,
          lastName: response.user?.name?.lastName,
          matchingEmail,
          isGuestCheckoutOrderComplete,
        },
      });
    } catch (e) {
      onError && onError(e);
    }
  }

  useEffect(() => {
    if (scriptStatus === 'ready') {
      initAppleSignIn();

      document.addEventListener(
        'AppleIDSignInOnSuccess',
        handleAppleIDSignInOnSuccess
      );
      trackAnalyticsEvent(
        'Apple Oauth Displayed',
        { oauth: appleAuthButtonQAID },
        true
      );
    }
    return () => {
      document.removeEventListener(
        'AppleIDSignInOnSuccess',
        handleAppleIDSignInOnSuccess
      );
    };
  }, [handleAppleIDSignInOnSuccess, scriptStatus]);

  useEffect(() => {
    const sessionId = data?.createAppleSession?.sessionId;
    const isNewAccount = data?.createAppleSession?.isNewAccount;
    const user = data?.createAppleSession?.user;

    if (sessionId && user) {
      if (isNewAccount) {
        alertContext.addAlert({
          type: 'success',
          message: <AccountCreatedSuccess user={user} isAppleAuth />,
          id: 'account-created-snackbar',
        });
      }
      onSuccess({ user });
    }
  }, [data?.createAppleSession]);

  if (scriptStatus !== 'ready') {
    return null;
  }

  return (
    <>
      <Box display="none" id="appleid-signin" ref={ref} />
      <ThirdPartyAuthButton
        onClick={handleClick}
        data-qaid={appleAuthButtonQAID}
        backgroundColor={colors.primary.black.base}
        borderColor={colors.primary.black.base}
        color={colors.primary.white.lightest}
        provider="Apple"
      />
    </>
  );
};

export default AppleLoginButton;
