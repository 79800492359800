import React from 'react';
import { ApolloProvider } from '@apollo/client';

import apolloClient from './sofarApolloClient';

const SofarApolloProvider = ({ children }: { children: React.ReactNode }) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default SofarApolloProvider;
