import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { FontSizePropKey } from 'app/shared/theme';
import { useMultiStepSignUpContext } from 'app/shared/context';
import { AuthContext } from 'app/shared/context/Auth';
import { WithSpacingProps } from 'app/shared/components/styleUtils';
import { Box, Typography } from 'app/shared/components/atoms';
import {
  ButtonsSectionProps,
  ModalButtonsSection,
  ModalTitle,
} from 'app/shared/components/molecules';

import { SmsSignUpInputWithDisclaimer } from './SmsSignUpInputWithDisclaimer';
import { ValidationSchema } from './ValidationSchema';

export { SmsSignUpInputWithDisclaimer };

interface SmsSignUpFormProps extends WithSpacingProps, ButtonsSectionProps {
  formContext: string;
  backgroundColor?: string;
  inputBackgroundColor?: string;
  inputColor?: string;
  title: string | null;
  titleFontSize?: FontSizePropKey;
  description?: string;
  hide?: boolean;
  isSubmitButtonFullWidth?: boolean;
  onSuccess?: () => void;
  secondaryCtaDataQaid?: string;
  secondaryCtaText?: string;
  onClickSecondaryCta?: () => void;
  disableClose?: boolean;
  alignItems?: string;
}

export const SmsSignUpForm = ({
  formContext,
  backgroundColor,
  inputBackgroundColor,
  inputColor,
  title,
  titleFontSize,
  description,
  hide = false,
  isSubmitButtonFullWidth = false,
  onSuccess,
  secondaryCtaDataQaid,
  secondaryCtaText,
  onClickSecondaryCta,
  disableClose,
  alignItems = 'center',
  ...props
}: SmsSignUpFormProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { user } = useContext(AuthContext);
  const {
    apiError,
    success,
    newUser,
    smsMarketingSignUpForm,
    inferredUserCountryCode,
  } = useMultiStepSignUpContext();
  const { onSmsSignUpSubmit, initialFormValues, shouldDisplaySmsOptIn } =
    smsMarketingSignUpForm({ formContext, hide, onSuccess });
  const { formatMessage } = useIntl();
  const validationSchema = ValidationSchema(formatMessage);

  if (!shouldDisplaySmsOptIn) {
    return null;
  }

  return (
    <Box
      backgroundColor={backgroundColor}
      data-qaid={`${formContext}-form`}
      gap={2}
      flexDirection="column"
      alignItems={alignItems}
      {...props}
    >
      {title && (
        <ModalTitle
          title={title}
          fontSize={titleFontSize}
          disableClose={disableClose}
        />
      )}
      <Typography mb={6}>
        {description || formatMessage({ id: 'smsSignUp.description' })}
      </Typography>
      <Formik
        initialValues={initialFormValues}
        onSubmit={(values, { setSubmitting }) => {
          onSmsSignUpSubmit({ smsFormData: values, setSubmitting });
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <SmsSignUpInputWithDisclaimer
              mb={8}
              backgroundColor={backgroundColor}
              inputBackgroundColor={inputBackgroundColor}
              inputColor={inputColor}
              apiError={apiError}
              label="Mobile number"
              formContext={formContext}
              isStandaloneForm={true}
              isLoaded={isLoaded}
              setIsLoaded={setIsLoaded}
              user={newUser || user}
              shouldDisplaySmsOptIn={shouldDisplaySmsOptIn}
              inferredUserCountryCode={inferredUserCountryCode}
              {...props}
            />
            <ModalButtonsSection
              primaryCtaType="submit"
              mobileFlexDirection="column"
              primaryCtaText={formatMessage({
                id: success ? 'shared.signedUpForSms' : 'shared.signUpForSms',
              })}
              isPrimaryCtaLoading={props.isSubmitting}
              primaryCtaDataQaid={
                success ? 'sms-sign-up-success' : 'sms-sign-up-submit'
              }
              isPrimaryCtaDisabled={
                !!props.errors.mobile ||
                !props.values.mobile ||
                props.isSubmitting ||
                success
              }
              isPrimaryCtaFullWidth={isSubmitButtonFullWidth}
              secondaryCtaDataQaid={secondaryCtaDataQaid}
              secondaryCtaText={secondaryCtaText}
              onClickSecondaryCta={onClickSecondaryCta}
            />
          </form>
        )}
      </Formik>
    </Box>
  );
};
